.title {
  font-size: 18px;
  margin-bottom: 12px;
}

.link {
  margin-top: 20px;
  font-size: 14px;
  text-decoration: none;
  color: #000000;
}

.authForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.registerForm {
  min-width: 300px;
  max-width: 575px;
  @media only screen and (min-width: 700px) {
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .input {
      width: 280px;
    }

    .inputEmail {
      width: 100%;
      max-width: none;
    }
  }

  &.column {
    flex-direction: column;
  }
}

.buttonContainer {
  width: 100%;
  display: flex;
  justify-content: center;
}

.errorMessage {
  text-align: center;
  font-size: 14px;
  color: #f44336;
  margin-top: 10px;
}

.message {
  margin: 25px 0;
}

.validationMessage {
  color: #f44336;
}

.captcha {
  width: 100%;
  display: flex;
  justify-content: center;
}
