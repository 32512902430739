// add styles here
:global(.Toastify__toast-container) {
  width: 400px !important;
}

// .Toastify__toast {
// }
:global(.Toastify__toast ) {
  width: 400px;
}
