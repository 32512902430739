@import '../../theme/colors.module.scss';


.pageContainer {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #bbbbbb;
}

.container {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.heading {
  color: #ebebeb;
  font-size: 20px;
  &::after {
    content: '...';
    position: absolute;
  }
}

.spinner {
  color: #ebebeb;
}

