@import '../../../theme/colors.module.scss';

.authContainer {
  width: 100%;
  height: 100vh;
  background-color: $c-main;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  }

.contentContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #ffffff;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 3px;
  padding: 56px 64px 20px;
  box-sizing: border-box;
  min-width: 320px;
  max-height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;

  .logo {
    width: 135px;
  }

  .title {
    margin-top: 20px;
    color: $c-main;
    font-size: 18px;
    margin-bottom: 5px;
  }

  .version {
    color: #000000;
    text-transform: uppercase;
    font-size: 12px;
    margin-bottom: 26px;
  }
}

.info {
  margin-top: 45px;
  text-align: center;
  color: #7D7B81;
  font-size: 10px;
}
