.content {
  height: 45vh;
  overflow-y: auto;
  padding-right: 10px;
}

.agreement {
  white-space: pre;
}

.controls {
  display: flex;
  justify-content: center;
  margin-top: 20px;

  @media only screen and (max-width: 500px) {
    flex-direction: column;
    width: 100%;
  }

  .controlButton {
    margin: 0px 15px;
    @media only screen and (max-width: 500px) {
      margin-bottom: 10px;
    }
  }
}

// containers

.receiptsListContainer {
  display: flex;
  justify-content: center;
  text-align: center;
  margin-bottom: 80px;
}

.contactFormContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  .title {
    margin-bottom: 35px;
  }

  .input {
    max-width: none;
  }
}
