.container {
  position: relative;
  width: 100%;
  max-width: 180px;
  min-width: 165px;
  @media only screen and (max-width: 700px) {
    max-width: 300px;
  }
}

.this {
  :global(.MuiFormHelperText-root.Mui-error) {
    position: absolute;
    top: 48px;
    white-space: nowrap;
  }
}

:global(.MuiFormControl-root) {
  width: 100%;
}

.closeButton {
  position: absolute;
  right: 50px;
  top: 18px;
  padding: 4px;

  .icon {
    font-size: 1.25rem;
  }
}
