.overlay {
  position: fixed;
  inset: 0px;
  background-color: #00000050;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.contentContainer {
  width: 80%;
  max-width: 700px;
  max-height: 100vh;
  overflow: auto;
  outline: none;
}

.this {
  width: 100%;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  outline: none;
}

.header {
  padding: 10px 30px;
  color: #ffffff;
  font-size: 20px;
  font-weight: 600;
}

.content {
  padding: 40px;
  color: #454a4e;
  font-size: 16px;
  line-height: 24px;
}
