// THEME
//
// main color (for layout, header etc...)
$c-main: #62ab85;

//
// secondary color (for background images color)
$c-secondary: #62ab85;

// BUTTONS
//
// Buttons primary color (for ative, success, main buttons)
$c-button-primary: #2f5e8c;
//
// Buttons secondary color (for cancel, disabled buttons)
$c-button-secondary: #86898c;
