@import '../../theme/colors.module.scss';

.title {
  font-size: 18px;
  margin-bottom: 12px;
}

.button {
  text-transform: none;
  background-color: $c-main;
  color: #ffffff;
  font-size: 16px;
  font-weight: 400;
  margin-top: 15px;
  padding: 8px 30px;
}

.link {
  margin-top: 20px;
  font-size: 14px;
  text-decoration: none;
  color: #000000;
}
